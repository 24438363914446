@tailwind base;
@tailwind components;
@tailwind utilities;

.rich-text-editor {
  @apply w-full rounded-lg border border-input bg-background;
}

.ql-editor {
  @apply min-h-[200px] space-y-2;
}

.ql-container.ql-snow {
  @apply border-none;
}

.ql-container.ql-bubble {
  @apply border rounded-md;
}

.ql-toolbar.ql-snow {
  @apply border-none bg-gray-50;
}

.rich-text-editor .ql-container.ql-bubble:not(.ql-disabled) a::before,
.rich-text-editor .ql-container.ql-bubble:not(.ql-disabled) a::after {
  content: none;
}

.ql-editor ol,
.ql-editor ul {
  @apply !pl-1;
}
